export default [
    {
        "label": "WVL",
        "value": "1",
        "isChecked": false
    },
    {
        "label": "OVL",
        "value": "2",
        "isChecked": false
    },
    {
        "label": "ANTW",
        "value": "3",
        "isChecked": false
    },
    {
        "label": "LIM",
        "value": "4",
        "isChecked": false
    },
    {
        "label": "VL-BR",
        "value": "5",
        "isChecked": false
    },
    {
        "label": "BRSS",
        "value": "6",
        "isChecked": false
    }
]