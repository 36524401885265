import React, { useEffect, useState } from "react"
import * as style from "./checkboxes.module.scss"

const Checkboxes = ({options = [], canSelectAll = true, onCheck}) => {
    const all = options.map(opt => opt.value)
    const [allOptions, setAllOptions] = useState(options)
    const [checkedOptions, setCheckedOptions] = useState([])

    const checkAll = () => {
        let itemList = []
        if(all.length !== checkedOptions.length) {
            itemList = all
        }

        setCheckedOptions(itemList)
        onCheck(itemList)
        const newAllOptions = allOptions.map(option => ({...option, isChecked: (option.isChecked ? !option.isChecked : true)}))
        setAllOptions(newAllOptions)
    }

    const removeItem = (itemToBeRemoved) => {
        let newList = checkedOptions.filter(item => item !== itemToBeRemoved);
        setCheckedOptions(newList)
        return newList
    }

    const addItem = (itemToBeAdded) => {
        let newList = [...checkedOptions, itemToBeAdded]
        setCheckedOptions(newList)
        return newList
    }

    const handleCheck = (value) => {
        let newList = [];
        if(checkedOptions.includes(value)) {
            newList = removeItem(value)
        }else {
            newList = addItem(value)
        }

        onCheck(newList)
            
    }

    return <div className={style.checkboxes}>
            {canSelectAll && <div className={style.containerCheckbox}>
                <input type="checkbox" onClick={checkAll}/>
                <label>ALL</label>
            </div>}
        
        {
            allOptions.map(option =>   <div className={style.containerCheckbox} key={option.value}>
                                        <input type="checkbox" name={'checkboxes'} onChange={() => handleCheck(option.value)} defaultChecked={option.isChecked} disabled={option.isChecked} />
                                        <label>{option.label}</label>
                                    </div>
            )
        }
           </div>
}


export default Checkboxes